import React, { useState } from "react";
import "./App.css";
import ComingSoon from "./coming-soon/ComingSoon";
import AppContent from "./routes/AppContent";

function App() {
  const [comingSoon] = useState(false);
  return (
    <div className="App">{comingSoon ? <ComingSoon /> : <AppContent />}</div>
  );
}

export default App;
