import React from "react";
import "./style.css";
import logo from "../assets/logotipo-the-alternative.png";
import serviziBg from "../assets/serivizi-background.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Valutazione from "../components/Valutazione";

const Servizi = () => {
  const { t } = useTranslation();
  return (
    <div className="container-servizi">
      <div className="servizi-header">
        <Link to="/">
          <img src={logo} alt="logo" className="img-logo-servizi" />
        </Link>
      </div>
      <div className="servizi-container-text">
        <img src={serviziBg} alt="logo" className="bg-servizi" />
        <Valutazione />
        <div className="container-inner-text">
          <div className="grid-col">
            <h2>{t("services")}</h2>
            <div className="first-container">
              <h4>Caveau</h4>
              <p>
                {t("caveau_1")} {t("caveau_2")}
              </p>
            </div>
            <div className="first-container">
              <h4>Nft</h4>
              <p>{t("consulting_1")}</p>
            </div>
          </div>
          <div className="grid-col">
            <h2 style={{ visibility: "hidden" }}>{t("services")}</h2>
            <div className="first-container">
              <h4>{t("events")}</h4>
              <p>
                {t("events_1")} {t("events_2")}
              </p>
            </div>
            <div className="first-container">
              <h4>{t("consulting")}</h4>
              <p>
                {t('nft_text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servizi;
