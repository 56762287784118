import emailjs from "@emailjs/browser";
import React, { useState, useRef } from "react";
import "./style.css";
import logo from "../assets/logotipo-the-alternative.png";
import serviziBg from "../assets/contatti-background.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { email_costants } from "../COSTANTS";

function Contatti() {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [oggetto, setOggetto] = useState("");

  const [loading, setLoading] = useState(false);
  const form = useRef();

  function sendEmail(e) {
    e.preventDefault();

    console.log(name, message, email, oggetto);
    setLoading(true);
    emailjs
      .sendForm(
        email_costants.emailjs_serviceID,
        email_costants.emailjs_templateID_contact,
        form.current,
        email_costants.key
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          emptyAll();
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
        }
      );
  }

  function emptyAll() {
    setName("");
    setEmail("");
    setMessage("");
    setOggetto("");
  }

  return (
    <div className="container-servizi">
      <div className="servizi-header">
        <Link to="/">
          <img src={logo} alt="logo" className="img-logo-servizi" />
        </Link>
      </div>
      <div className="servizi-container-text">
        <img src={serviziBg} alt="logo" className="bg-servizi" />
        <div className="container-title-form">
          <div className="grid-col">
            <h2 className="title-contattaci">{t("contact_us")}</h2>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-contattaci">
              <div className="first-row">
                <input
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  name="name"
                  required
                  value={name}
                  placeholder={t("name")}
                />
                <input
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  name="email"
                  required
                  value={email}
                  placeholder={t("email")}
                />
              </div>
              <div className="long-row">
                <input
                  type="text"
                  onChange={(e) => {
                    setOggetto(e.target.value);
                  }}
                  name="object"
                  required
                  value={oggetto}
                  placeholder={t("obj")}
                />
              </div>
              <div className="long-row">
                <textarea
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  name="message"
                  required
                  value={message}
                  placeholder={t("message")}
                />
              </div>
            </div>
            <button
              className={loading ? "submit-btn-disabled" : "submit-btn"}
              type="submit"
            >
              {t("submit")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contatti;
