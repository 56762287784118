import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function Menu() {
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  // const navigate = useNavigate();
  return (
    <>
      {window.innerWidth > 600 && (
        <div className="menu-row">
          <div className="menu-left">
            <a
              className="link-top-bar"
              href="https://oncyber.io/spaces/7kNFZaZjtAOnak9WxCYX"
              target="_blank"
              rel="noreferrer"
            >
              {t("gallery")}
            </a>

            <Link className="link-top-bar" to="/servizi">
              {t("services")}
            </Link>

            {/* <a
              className="link-top-bar"
              onClick={() => {
                setShowMenu(false);
              }}
              href="/#nft"
            >
              Nft
            </a> */}

            <Link
              onClick={() => {
                setShowMenu(false);
              }}
              to="/contatti"
            >
              {t("cont")}
            </Link>
          </div>

          <div className="div-lng">
            <span
              onClick={() => {
                i18n.changeLanguage("en");
                window.scrollTo(0, 0);
              }}
            >
              ENG
            </span>
            <span
              onClick={() => {
                i18n.changeLanguage("it");
                window.scrollTo(0, 0);
              }}
            >
              ITA
            </span>
          </div>
        </div>
      )}

      {window.innerWidth < 600 && (
        <>
          <div className="menu-row">
            <button
              onClick={() => {
                setShowMenu(true);
                window.scrollTo(0, 0);
                document.body.style.overflow = "hidden";
              }}
            >
              MENU
            </button>
          </div>
          <div
            className={
              showMenu ? "menu-mobile-screen-visible" : "menu-mobile-screen"
            }
          >
            <div className="header-mobile-menu">
              <button
                onClick={() => {
                  setShowMenu(false);
                  document.body.style.overflow = "auto";
                }}
              >
                x
              </button>
            </div>
            <div className="header-body-mobile">
              <a
                className="link-top-bar"
                href="https://oncyber.io/spaces/7kNFZaZjtAOnak9WxCYX"
                target="_blank"
                rel="noreferrer"
              >
                {t("gallery")}
              </a>

              <Link
                className="link-top-bar"
                onClick={() => {
                  setShowMenu(false);
                  document.body.style.overflow = "auto";
                }}
                to="/servizi"
              >
                {t("services")}
              </Link>

              {/* <a
                className="link-top-bar"
                onClick={() => {
                  setShowMenu(false);
                }}
                href="/#nft"
              >
                Nft
              </a> */}

              <Link
                onClick={() => {
                  setShowMenu(false);
                  document.body.style.overflow = "auto";
                }}
                to="/contatti"
              >
                {t("cont")}
              </Link>
            </div>
            <div className="footer-mobile">
              <span
                onClick={() => {
                  i18n.changeLanguage("en");
                  window.scrollTo(0, 0);
                  setShowMenu(false);
                  document.body.style.overflow = "auto";
                }}
              >
                ENG
              </span>
              <span
                onClick={() => {
                  i18n.changeLanguage("it");
                  window.scrollTo(0, 0);
                  setShowMenu(false);
                  document.body.style.overflow = "auto";
                }}
              >
                ITA
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Menu;
