import React from "react";
import Menu from "../menu/Menu";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../home/Home";
import HomeMobile from "../home/HomeMobile";
import Servizi from "../servizi/Servizi";
import Contatti from "../contatti/Contatti";

export default function AppContent() {
  return (
    <div className="main-container">
      <Router>
        <div className="main-cont">
          <Menu />
          <Routes>
            {window.innerWidth < 500 ? (
              <>
                <Route path="/" element={<HomeMobile />} />
                <Route path="/servizi" element={<Servizi />} />
                <Route path="/contatti" element={<Contatti />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/servizi" element={<Servizi />} />
                <Route path="/contatti" element={<Contatti />} />
              </>
            )}
          </Routes>
        </div>
      </Router>
    </div>
  );
}
