import React from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

//import immagini
import bgOne from "../assets/sec-1-bg.png";
import bgTwo from "../assets/sec-2-bg.png";
import bgThree from "../assets/sec-3-bg.png";
import bgFour from "../assets/sec-4-bg.png";
import bgFive from "../assets/sec-5-bg.png";
import bgSix from "../assets/sec-6-bg.png";
import Sidebar from "../sidebar/Sidebar";

import "./style.css";

function Home() {
  const { ref: sectionUnoRef, inView: sectionUnoVisible } = useInView();
  const { ref: sectionDueRef, inView: sectionDueVisible } = useInView();
  const { ref: sectionTreRef, inView: sectionTreVisible } = useInView();
  const { ref: sectionQuattroRef, inView: sectionQuattroVisible } = useInView();
  const { ref: sectionCinqueRef, inView: sectionCinqueVisible } = useInView();
  const { ref: sectionSeiRef, inView: sectionSeiVisible } = useInView();

  const { t } = useTranslation();

  return (
    <>
      <div className="sidebar-cont">
        <Sidebar
          menuState={[
            sectionUnoVisible,
            sectionDueVisible,
            sectionTreVisible,
            sectionQuattroVisible,
            sectionCinqueVisible,
            sectionSeiVisible,
          ]}
        />
      </div>
      <div className="container-home">
        <section className="sect-1" id="progetto">
          <img
            src={bgOne}
            alt="background first section"
            className="background-image"
          />

          <p ref={sectionUnoRef}>
            {t("projectText")} <br /> <br /> {t("projectText_1")} <br /> <br />{" "}
            {t("projectText_2")}
          </p>
        </section>

        <section className="sect-2" id="vintage-cars">
          <img
            src={bgTwo}
            alt="background second section"
            className="background-image"
          />
          <div>
            <h3 ref={sectionDueRef}>Vintage Cars</h3>
            <p>
              {t("vintage_cars_1")} <br /> <br /> {t("vintage_cars_2")}
              <br /> <br /> {t("vintage_cars_3")}
            </p>
          </div>
        </section>
        <section className="sect-3" id="fine-wines">
          <img
            src={bgThree}
            alt="background third section"
            className="background-image"
          />
          <div>
            <h3 ref={sectionTreRef}>Fine Wines</h3>
            <p>
              {t("fine_wines_1")} <br /> • {t("fine_wines_2")} <br /> •
              {t("fine_wines_3")} <br /> • {t("fine_wines_4")}
              <br /> {t("fine_wines_5")}
            </p>
          </div>
        </section>
        <section className="sect-4" id="orologi-e-gioielli">
          <img
            src={bgFour}
            alt="background four section"
            className="background-image"
          />
          <div>
            <h3 ref={sectionQuattroRef}>Watches and Jewelry</h3>
            <p>
              {t("watches_1")}
              <br />
              <br /> {t("watches_2")}
              <br /> • {t("watches_3")}
              <br /> • {t("watches_4")}
              <br /> • {t("watches_5")}
              <br />
              <br /> {t("watches_6")}
            </p>
          </div>
        </section>
        <section className="sect-5" id="fine-arts">
          <img
            src={bgFive}
            alt="background five section"
            className="background-image"
          />
          <div>
            <h3 ref={sectionCinqueRef}>Fine Arts</h3>
            <p>
              {t('fine_arts_1')}
              <br />
              <br /> {t('fine_arts_2')}
              <br /> • {t('fine_arts_3')}
              <br /> • {t('fine_arts_4')}
              <br />
              <br /> {t('fine_arts_5')}
            </p>
          </div>
        </section>
        <section className="sect-6" id="nft">
          <img
            src={bgSix}
            alt="background six section"
            className="background-image"
          />
          <div>
            <h3 ref={sectionSeiRef}>NFT</h3>
            <p>
            {t('nft_1')} <br /> {t('nft_2')} <br /> {t('nft_3')}
            </p>
            <h5>ROADMAP</h5>
            <div className="roadmap-div">
              <div className="div-1-roadmap"></div>
              <p>{t('nft_4')}</p>
            </div>
            <div className="roadmap-div">
              <div className="div-2-roadmap"></div>
              <p>
              {t('nft_5')}
              </p>
            </div>
            <div className="roadmap-div">
              <div className="div-3-roadmap"></div>
              <p>{t('nft_6')}</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
