import React from "react";
import "./style.css";
import logo from "../assets/logotipo-the-alternative.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Sidebar({ menuState }) {
  const { t } = useTranslation();
  console.log(menuState[4]);
  return (
    <div className="sidebar-col">
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
      <div className="navbar-menu">
        <div className={menuState[0] ? "active-link-sidebar" : ""}>
          <a href="#progetto">{t('the_proj')}</a>
        </div>
        <div className={menuState[1] ? "active-link-sidebar" : ""}>
          <a href="#vintage-cars">Vintage Cars</a>
        </div>
        <div
          className={menuState[2] ? "active-link-sidebar fine-wines-div" : ""}
        >
          <a href="#fine-wines">Fine Wines</a>
        </div>
        <div className={menuState[3] ? "active-link-sidebar orologi-div" : ""}>
          <a href="#orologi-e-gioielli">Watches & Jewelry</a>
        </div>
        <div
          className={menuState[4] ? "active-link-sidebar fine-arts-div" : ""}
        >
          <a href="#fine-arts">Fine Arts</a>
        </div>
        <div className={menuState[5] ? "active-link-sidebar nft-div" : ""}>
          <a href="#nft">NFT</a>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
