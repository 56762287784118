import React from "react";
import "./style.css";
import logo from "../assets/logo-the-alternative.png";

export default function ComingSoon() {
  return (
    <div className="background-cs">
      <div className="div-cs-text">
        <img className="logo-ta" src={logo} alt="logo the alternative" />
        <p className="cs-text">Coming Soon</p>
      </div>
    </div>
  );
}
