import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEn from "./locals/en/translationEN.json";
import translationIT from "./locals/it/translationIT.json";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: translationEn },
      it: { translation: translationIT },
    },
    lng: "it",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });
