import React from "react";
import "./style.css";
import logo from "../assets/logotipo-the-alternative.png";
import { Link } from "react-router-dom";

function SidebarMobile({ menuState }) {
  return (
    <>
      <div className="sidebar-col">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
        <div className="navbar-menu">
          {menuState[0] ? (
            <div>
              <a style={menuState[0] && { color: "black", marginBottom: 2 }} href="#progetto">
                Progetto
              </a>
            </div>
          ) : (
            <div className="pallina-stronza"></div>
          )}
          {menuState[1] ? (
            <div>
              <a href="#vintage-cars">Vintage Cars</a>
            </div>
          ) : (
            <div  className="pallina-stronza"></div>
          )}
          {menuState[2] ? (
            <div>
              <a href="#fine-wines">Fine Wines</a>
            </div>
          ) : (
            <div className="pallina-stronza"></div>
          )}
          {menuState[3] ? (
            <div>
              <a href="#orologi-e-gioielli">Watches & Jewelry</a>
            </div>
          ) : (
            <div className="pallina-stronza"></div>
          )}
          {menuState[4] ? (
            <div>
              <a href="#fine-arts">Fine Arts</a>
            </div>
          ) : (
            <div className="pallina-stronza"></div>
          )}
          {menuState[5] ? (
            <div>
              <a href="#nft">NFT</a>
            </div>
          ) : (
            <div className="pallina-stronza"></div>
          )}
        </div>
      </div>
    </>
  );
}

export default SidebarMobile;
