import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import bgOneMob from "../assets/mobile/section-1-mob.png";
import bgTwpMob from "../assets/mobile/section-2-mob.png";
import bgTreMob from "../assets/mobile/section-3-mob.png";
import bg4Mob from "../assets/mobile/section-4-mob.png";
import bg5Mob from "../assets/mobile/section-5-mob.png";
import bgSix from "../assets/sec-6-bg.png";
import SidebarMobile from "../sidebar/SidebarMobile";

function HomeMobile() {
  const { ref: sectionUnoRef, inView: sectionUnoVisible } = useInView();
  const { ref: sectionDueRef, inView: sectionDueVisible } = useInView();
  const { ref: sectionTreRef, inView: sectionTreVisible } = useInView();
  const { ref: sectionQuattroRef, inView: sectionQuattroVisible } = useInView();
  const { ref: sectionCinqueRef, inView: sectionCinqueVisible } = useInView();
  const { ref: sectionSeiRef, inView: sectionSeiVisible } = useInView();

  const { t } = useTranslation();

  return (
    <>
      <div className="sidebar-cont">
        <SidebarMobile
          menuState={[
            sectionUnoVisible,
            sectionDueVisible,
            sectionTreVisible,
            sectionQuattroVisible,
            sectionCinqueVisible,
            sectionSeiVisible,
          ]}
        />
      </div>
      <div className="container-home">
        <section id="progetto">
          <img
            alt="sfondo progetto alternative"
            className="background-image"
            src={bgOneMob}
          />
          <p className="text-1">{t("projectText")}</p>
          <p className="text-2" ref={sectionUnoRef}>
            {t("projectText_1")} <br /> <br /> {t("projectText_2")}
          </p>
        </section>
        <section id="vintage-cars">
          <img
            alt="sfondo vintage cars the alternative"
            className="background-image"
            src={bgTwpMob}
          />
          <p className="text-1">
            <h3 className="title-text-mob">Vintage Cars</h3>
            {t("vintage_cars_1")}
          </p>
          <p className="text-2" ref={sectionDueRef}>
            {t("vintage_cars_2")}
            <br /> <br /> {t("vintage_cars_3")}
          </p>
        </section>
        <section id="fine-wines">
          <img
            alt="sfondo fine wines the alternative"
            className="background-image"
            src={bgTreMob}
          />
          <p className="text-1">
            <h3 className="title-text-mob">Fine Wines</h3>
            {t("fine_wines_1")}
          </p>
          <p className="text-2" ref={sectionTreRef}>
            <br /> • {t("fine_wines_2")} <br /> • {t("fine_wines_3")} <br /> •{" "}
            {t("fine_wines_4")}
            <br /> {t("fine_wines_5")}
          </p>
        </section>
        <section id="orologi-e-gioielli">
          <img
            alt="sfondo watches and jewelry the alternative"
            className="background-image"
            src={bg4Mob}
          />
          <p className="text-1">
            <h3 className="title-text-mob">Watches & Jewelry</h3>
            {t("watches_1")}
          </p>
          <p className="text-2" ref={sectionQuattroRef}>
            <br /> • {t("watches_2")}
            <br /> • {t("watches_3")}
            <br /> • {t("watches_4")} {t("watches_5")}
            <br />
            <br /> {t("watches_6")}
          </p>
        </section>
        <section id="fine-arts">
          <img
            alt="sfondo fine arts the alternative"
            className="background-image"
            src={bg5Mob}
          />
          <p className="text-1">
            <h3 className="title-text-mob">Fine Arts</h3>
            {t("fine_arts_1")}
          </p>
          <p className="text-2" ref={sectionCinqueRef}>
            <br /> • {t("fine_arts_2")}
            <br /> • {t("fine_arts_3")} {t("fine_arts_4")}
            <br />
            <br /> {t("fine_arts_5")}
          </p>
        </section>
        <section id="nft">
          <img
            alt="sfondo nft the alternative"
            className="background-image"
            src={bgSix}
          />
          <p className="text-1">
            <h3 className="title-text-mob">NFT</h3>
            {t("nft_1")} <br /> {t("nft_2")}
          </p>
          <p className="text-2" ref={sectionSeiRef}>
            {t("nft_3")}
            <h5>ROADMAP</h5>
            <div className="roadmap-div">
              <div className="row-roadmap">
                <div className="rettangolo-roadmap red"></div>
                <p className="text-roadmap-div">{t("nft_4")}</p>
              </div>
              <div className="row-roadmap">
                <div className="rettangolo-roadmap blue"></div>
                <p className="text-roadmap-div">{t("nft_5")}</p>
              </div>
            </div>
            <div className="row-roadmap">
              <div className="rettangolo-roadmap yel"></div>
              <p className="text-roadmap-div">{t("nft_6")}</p>
            </div>
          </p>
        </section>
      </div>
    </>
  );
}

export default HomeMobile;
