import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./style.css";
import { email_costants } from "../COSTANTS";
import { useTranslation } from "react-i18next";
import Nazioni from "../Nazioni";

function Valutazione() {
  const { t } = useTranslation();
  const form = useRef();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [brand, setBrand] = useState("");
  // const [startPrice, setStartPrice] = useState("");
  const [seriesNumber, setSeriesNumber] = useState("");
  const [provenienza, setProvenienza] = useState("");
  const [condition, setCondition] = useState("");
  const [reference, setReference] = useState("");
  const [anno, setAnno] = useState("");
  const [corredo, setCorredo] = useState("");
  const [notes, setNotes] = useState("");
  const [file, setFile] = useState("");

  function sendEmail(e) {
    e.preventDefault();

    console.log(
      "form datas: ",
      brand,
      // startPrice,
      seriesNumber,
      provenienza,
      condition,
      reference,
      anno,
      corredo,
      notes,
      file
    );
    setLoading(true);
    emailjs
      .sendForm(
        email_costants.emailjs_serviceID,
        email_costants.emailjs_templateID_valutazione,
        form.current,
        email_costants.key
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          emptyAll();
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
        }
      );
  }

  function emptyAll() {
    setName("");
    setEmail("");
    setBrand("");
    // setStartPrice("");
    setCondition("");
    setSeriesNumber("");
    setCorredo("");
    setProvenienza("");
    setReference("");
    setAnno("");
    setNotes("");
  }
  return (
    <div className="container-valutazione">
      <h2>{t("val_1")}</h2>
      <h4 className="subtitle-valutazione">{t("val_2")}</h4>
      <form className="form-valutazione" ref={form} onSubmit={sendEmail}>
        <div className="cont-form-val">
          <div className="first-row-val">
            <input
              type="text"
              placeholder={t("name")}
              onChange={(e) => {
                setName(e.target.value);
              }}
              name="name"
              value={name}
              required
            />
            <input
              type="text"
              placeholder={t("email")}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="email"
              value={email}
              required
            />
          </div>
          <div className="first-row-val">
            <input
              id="brand_list"
              placeholder={t("brand")}
              onChange={(e) => {
                setBrand(e.target.value);
              }}
              type="text"
              name="brand"
              value={brand}
              required
              list="brand-list"
            />
            <datalist id="brand-list">
              <option value="Patek Philippe" />
              <option value="Rolex" />
              <option value="Girard-Perregaux" />
              <option value="IWC" />
              <option value="Omega" />
              <option value="Tudor" />
              <option value="Vacheron Costantin" />
              <option value="F.P. Journe" />
              <option value="Richard Mille" />
            </datalist>
            <input
              type="text"
              placeholder={t("ref")}
              onChange={(e) => {
                setReference(e.target.value);
              }}
              name="reference"
              value={reference}
              required
            />
          </div>
          <div className="first-row-val">
            <input
              type="text"
              onChange={(e) => {
                setSeriesNumber(e.target.value);
              }}
              name="nr_serie"
              value={seriesNumber}
              placeholder={t("nr_serie")}
            />
            <input
              type="text"
              placeholder={t("year")}
              onChange={(e) => {
                setAnno(e.target.value);
              }}
              name="anno"
              value={anno}
            />
          </div>
          <div className="first-row-val">
            <select
              value={condition}
              onChange={(e) => {
                setCondition(e.target.value);
              }}
              name="condition"
              required
            >
              <option value="" disabled selected>
                {t("condition")}
              </option>
              <option value="Usato">{t("used")}</option>
              <option value="Nuovo/Mai indossato">{t("new_never_used")}</option>
            </select>
            <input
              type="text"
              placeholder={t("prov")}
              onChange={(e) => {
                setProvenienza(e.target.value);
              }}
              name="provenienza"
              value={provenienza}
              list="lista-nazioni"
            />
            <datalist id="lista-nazioni">
              {Nazioni.map((n) => (
                <option value={n.name} />
              ))}
            </datalist>
          </div>
          <div className="first-row-val">
            <select
              value={corredo}
              onChange={(e) => {
                setCorredo(e.target.value);
              }}
              required
              name="corredo"
            >
              <option value="" disabled selected>
                {t("corredo")}
              </option>
              <option value="Scatola">{t("box")}</option>
              <option value="Garanzia">{t("warranty")}</option>
              <option value="Scatola e Garanzia">{t("box_warranty")}</option>
            </select>
            <textarea
              type="text"
              placeholder={t("add")}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              name="note"
              value={notes}
              rows={4}
              cols={50}
            />
          </div>
          <div className="first-row-val">
            {/* <label className="label-input-file">
              {t("upload_file")}
              <input
                type="file"
                name="file_foto"
                value={file}
                onChange={(e) => {
                  console.log("foto: ", e.target.value);
                }}
              />
            </label> */}
          </div>
        </div>
        <button
          className={loading ? "submit-btn-disabled" : "submit-btn"}
          type="submit"
        >
          {t("submit")}
        </button>
      </form>
      <h6>
        *La valutazione ricevuta rappresenta un'offerta di acquisto effettiva
        solo previa visione dell'oggetto.
      </h6>
    </div>
  );
}

export default Valutazione;
